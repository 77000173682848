body {
    background-color: #edf2f7;

    ::-webkit-scrollbar {
        width: 3px !important;
        height: 12px !important;
    }

    ::-webkit-scrollbar-thumb {
        background: #aeaeae;
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
}

body, .scrollbar, .ant-dialog-wrap, .ant-drawer-body, .ant-drawer-wrapper-body, .ant-anchor-wrapper, textarea.ant-input {
    scrollbar-color: unset;
}


//reuse-tab
.alain-default__fixed .reuse-tab {
    display: none;
    bottom: 0;
    top: auto;
    left: auto;
    padding: 0;
}

.reuse-tab__line .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
}

@media screen and (max-width: 767px) {

    .alain-default__fixed .reuse-tab {
        position: fixed;
    }
}

//Sidebar
.alain-default__aside {
    margin-top: 55px;
    width: 255px;
    margin-bottom: 35px;
    z-index: 99;
}


ul.sidebar-nav li a {
    border-top: 1px solid #b0b0b0;
}

ul.sidebar-nav ul.sidebar-nav__sub li a {
    border-top: none;
    width: 101%;
}


.alain-default__collapsed .rps-createOrEdit {
    width: 100% !important;
    margin-left: 0 !important;
}

.rps-createOrEdit {
    width: calc(100% - 170px) !important;
    margin-left: 170px !important;
}

nz-table {
    width: 100% !important
}

.search {
    &__form {
        &.ant-form-vertical {
            .ant-form-item {
                margin-bottom: 16px;
                margin-right: 0;
                display: block;
            }

            .ant-form-item-label,
            .ant-form-item-control-wrapper {
                padding: 0 !important;
            }
        }

        margin-bottom: 0px !important;
    }
}

.wrap-text-label {
    overflow: inherit !important;
    white-space: break-spaces !important;
    text-align: left !important;

    label {
        min-height: 32px !important;
        height: unset !important;
        font-family: 200 !important;
    }
}

.text-disabled-color {
    color: rgba(0, 0, 0, 0.25);
}

.ant-alert {
    padding-right: 30px !important;
}

.modal-include-tabs .ant-alert {
    margin-bottom: 16px !important;
}

.ant-form-item-children-icon {
    margin-right: 5px;
}

.alain-default__content {
    padding: 0 10px;
}

.alain-default__content nz-input-group {
    width: 100%;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

//fiz nz Icon
.anticon svg {
    display: block;
}

//btn Custom
.btn-outline-success {
    color: #0CBA70;
    border-color: #0CBA70;
}

.no-select-text {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.page-header__title,
.page-header__action {
    margin-bottom: 5px;
}

.pageTitle {
    font-weight: bold;
    line-height: 45px;
    padding-left: 10px;
    font-size: 18px;
}

.ora-form-case {
    margin: 10px 0px 10px 0px !important;
    text-align: center;
}

.btn-form-case {
    margin: 0px 5px 0px 5px !important
}

.padding-0 {
    padding: 0;
    background: white !important;
}

.radion-custom {
    padding: 1em;
}

label {
    margin-bottom: 0px;
    display: inline-flex !important;
}

//ant-table
.ant-table-thead > tr > th {
    color: #000;
    background: #e5eaee;
}

//ant-card
.ora-custom {
    margin-bottom: 8px !important;

    .ant-card-body {
        padding: 8px !important;
    }
}

.ora-custom-card {
    margin-bottom: 0px !important;

    .ant-card-body {
        padding: 0 !important;
    }
}

.green {
    color: green !important;
}

.red {
    color: red !important;
}

.right {
    right: 0 !important;
    float: right !important;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-left: 100px !important;
}

.qr-img {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 15vh;
}

.mb-215 {
    margin-top: -215px;
}

.ora-label-custom {
    padding-left: 15px !important;
    padding-right: 6px !important;
    padding-top: 3.5px !important;
    padding-bottom: 3.5px !important;
    border: 1px solid #d3d3d3 !important;
    font-weight: 500;
    cursor: default;
}

.ora-label-custom .icon-c {
    padding-right: 6px !important;
}

.inp-nameUsing {
    display: flex;
    width: 100% !important;
}

.inp-nameUsing input#name {
    width: 90% !important;
}

.inp-nameUsing .icon-form {
    color: #089759;
    font-size: 20px;
    margin-left: 5px !important;
    width: 10% !important;
}

@media screen and (max-width: 1028px) {
    .qr-img {
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 768px) {
    .qr-img {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-left: 20px;
    }

    .rps-createOrEdit {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .inp-nameUsing {
        display: flex;
        width: 100% !important;
    }

    .inp-nameUsing input#name {
        width: 85% !important;
    }

    .inp-nameUsing .icon-form {
        color: #089759;
        font-size: 18px;
        margin-left: 5px !important;
        width: 10% !important;
    }
}

@media screen and (max-width: 578px) {
    .mar-left {
        margin-left: 0 !important;
    }

    .ml-10 {
        display: none !important;
    }

    .inp-nameUsing {
        display: flex;
        width: 100% !important;
    }

    .inp-nameUsing input#name {
        width: 85% !important;
    }

    .inp-nameUsing .icon-form {
        color: #089759;
        font-size: 15px;
        margin-left: 5px !important;
        width: 10% !important;
    }
}

@media screen and (max-width: 380px) {
    .ml-140 {
        margin-left: 25px !important;
    }

    .qr-img {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-left: 0;
    }
}

.hidden {
    display: none !important;
}

//ant-notification
.ant-notification-notice-message {
    color: #fff;
}

//ant-input
.ant-input[disabled] {
    color: #000;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    padding: 5px 10px !important;
    opacity: 1;
    pointer-events: all;
}

.ant-input {
    width: 100%;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #c2cad8 !important;
    border-radius: 4px;
    margin-top: 2px;
}

.ant-input[readonly] {
    background-color: #fff;
    cursor: not-allowed;
    opacity: 1;
    color: #000;
    pointer-events: none;
    border: none !important;
    border: none;
}


.border-none-group {
    .ant-input {
        border: none !important;
        height: unset !important;
    }
}


.ant-input[disabled-not-view] {
    color: #00000040;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}


.ant-input:focus,
.ant-input-focused {
    box-shadow: none;
}

//ant-select
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000;
    cursor: not-allowed;
    border: none;
    border: 1px solid #c2cad8 !important;
}

.ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
    display: none;
}

//ant-picker
.ant-picker.ant-picker-disabled {
    background-color: #f5f5f5;
    border: none;
    color: #000;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    padding: 3px 12px;
    border: 1px solid #c2cad8 !important;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.main-ora-date .ant-picker.ant-picker-disabled {
    border: none;
}

.ora-input-date-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}

.oda-select-dropdown hr {
    margin: 5px;
}

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//     width: 100%;
//     height: 36px !important;
//     padding: 3px 11px;
// }

.ant-select {
    margin-top: 2px;
}


.ant-form-item-explain,
.ant-form-item-extra {
    margin-top: 0px !important;
}

//modal

.modal-header {
    background: #035f96;
}

.ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    font-weight: 400 !important;
    font-size: 15px !important;
}


.ant-radio-button {
    span {
        span {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            height: 1.5em;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
}

// ant-number
nz-input-number {
    width: 100% !important;
    height: 36px !important
}

.l-footer {
    z-index: 999 !important;
    position: fixed;
    bottom: -11px;
    display: block;
    width: 100%;
    padding: 10px 0;
}

.alain-default__aside-inner {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.alain-default__aside-inner::-webkit-scrollbar {
    width: 0px !important;
}

//custom-table.css

// .ant-table-tbody .ant-table-row:nth-child(even) {
//     background-color: #f0f0f0;
// }
.ant-back-top {
    z-index: 9999 !important;
}

.ant-menu-submenu-title .anticon + span {
    color: #1A202C;
    font-weight: 500;
}

// .cdk-overlay-pane {
//     left: 70px !important;
// }
.ant-menu ul {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.ant-menu ul:last-child {
    margin-bottom: 5px;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 18px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.mr-sm {
    margin-right: 8px !important;
    color: #596579;
}

.ant-dropdown ul {
    margin-top: -3px !important;
    min-width: unset !important;
    padding: 0px;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

label {
    color: #252525;
}

.title-excel {
    font-weight: bold;
    line-height: 45px;
    padding-left: 0px;
    font-size: 18px;
}

.custom-sticky-left {
    position: sticky !important;
    z-index: 2;
    background: #fff
}

.custom-sticky-right {
    position: sticky !important;
    z-index: 2;
    background: #fff;
    border-left: 1px solid rgba(0, 0, 0, .06) !important;
}

.day-year {
    display: flex !important;

    .ant-select-selector {
        height: 36px !important;
    }

    .ora-input-date {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

.title-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;

    span {
        font-weight: 600;
    }
}

.ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 36px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 36px;
    padding: 3px 11px;
}

.ant-btn-icon-only {
    height: 36px;
}

.ant-btn {
    height: 36px;
}

.ora-calendar {
    position: absolute;
    top: 12px !important;
    right: 5px;
}

:host::ng-deep {

    .enum-combo-margin-top-0 {
        .ant-select {
            margin-top: 0px !important;
        }
    }

    nz-input-group {
        display: flex !important;

        enum-combo {
            width: fit-content !important;

            nz-select-top-control {
                border-radius: unset !important;
                border-top-right-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
            }
        }
    }

    .ant-input[disabled] {
        color: black !important;
        background-color: #f5f5f5 !important;
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        opacity: 1 !important;
    }

    .ant-input[readonly] {
        color: black !important;
        background-color: #f5f5f5 !important;
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        opacity: 1 !important;
    }

    .disable {
        color: black !important;
        background-color: #f5f5f5 !important;
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        opacity: 1 !important;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: black !important;
        background-color: #f5f5f5 !important;
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        opacity: 1 !important;
    }

    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: black !important;
    }


    co-so-combo {
        .ant-input[disabled] {
            padding: 6px 12px !important;
            border: 1px solid #c2cad8 !important
        }
    }

    don-vi-hanh-chinh-chinh-combo {
        .ant-input[disabled] {
            padding: 6px 12px !important;
            border: 1px solid #c2cad8 !important;
        }
    }
}

.cartd-dashboard {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    border-radius: 5px;
    padding-left: 10px !important;

    .text-header-dashboard {
        font-size: 16px !important;
        line-height: 22px;
        color: #00000073;
        display: flex;
        font-weight: 600;

        .title-card-dashboard {
            margin-left: 10px;
        }
    }

    .text-body-dashboard {
        overflow: hidden;
        height: 38px;
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 30px;
        line-height: 38px;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        font-weight: 600;
    }
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: black !important;
}

.ant-radio-disabled .ant-radio-inner::after {
    background-color: #000000e6 !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: black !important;
}

.ant-radio-disabled + span {
    color: black !important;
}

.title-group-h3-muc {
    margin: 18px 0 16px;
    color: #035f96;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 2px solid;
    display: inline-block;
}

.title-group-h3 {
    margin: 18px 0 16px;
    color: #035f96;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 700;
    border-bottom: 2px solid;
    display: inline-block;
}

.ant-collapse-header {
    padding-top: 0px !important;
    padding-bottom: 10px !important;

    span {
        padding-top: 0px !important;
    }
}

.ant-checkbox {
    margin-top: 2px;
}

.alain-default__nav-item, .alain-default__nav nz-badge {
    color: #037bc2 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

::ng-deep .custom-nz-modal {
    top: 10px !important;

    .ant-modal-body {
        text-align: center !important;
        font-size: 16px !important;
    }

    .ant-modal-confirm-icon {
        font-size: 48px !important;
        display: flex !important;
        justify-content: center !important;
        margin-bottom: 16px !important;
    }

    .ant-modal-confirm-btns {
        display: flex !important;
        justify-content: center !important;
    }

    .ant-btn-primary {
        width: 100px !important;
        height: 40px !important;
    }

    .ant-modal-confirm-content {
        font-size: 14px !important;
        max-width: 500px !important;
        margin: auto !important;
    }
}

.button-large {
    button {
        padding: 4px 80px;
        height: 38px;
        font-size: large;
        font-weight: bold;
    }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0px;
    padding-left: 7px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: .9em;
    margin-bottom: 5px;
    color: #000000a6;;
    border-radius: 2px;
    margin-right: 29px;
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    -webkit-margin-end: 4px;
    margin-inline-end: 4px;
    margin-bottom: 2px;
    -webkit-padding-start: 8px;
    padding-inline-start: 8px;
    -webkit-padding-end: 4px;
    padding-inline-end: 4px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid #f5f5f5;;
}

.ant-modal-wrap {
    margin: auto;
}

.ant-form-item-label {
    text-align: left;
}
.lh-36 {line-height: 36px}
