

.page-header__detail {
    .page-header__main {
        .page-header__row {
            .page-header__title {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
    .page-header__action, .page-header__extra {
        text-align: right;
    }

    .alain-default__nav-wrap {
        .alain-default__nav {
            .title-he-thong {
                font-size: 13px;
                margin: 0px;
                font-weight: bold;
            }
        }
    }

    .alain-default__content {
        margin: 62px 5px 45px 5px !important;
    }

    .ant-modal {
        width: 100% !important;
    }

    .alain-fullscreen {
        position: relative;
        top: -10px;
    }
}

@media (min-width: 768px) {
    .alain-default__nav-wrap {
        .alain-default__nav {
            .title-he-thong {
                font-size: 18px;
                margin: 0px 0px 0px 20px;
                font-weight: bold;
            }
        }
    }

    .alain-default__content {
        margin: 95px 5px 45px 240px !important;

        .footer-fixed-page {
            position: fixed;
            bottom: 0;
            left: 200px;
            width: calc(100% - 200px);
            z-index: 1;
        }
    }

    .alain-default__collapsed .alain-default__content {
        margin: 95px 5px 45px 70px !important;


        .footer-fixed-page {
            left: 64px;
            width: calc(100% - 64px);
        }
    }

    .alain-default__fixed .reuse-tab + router-outlet {
        height: 0px !important;
    }

    .alain-fullscreen {
        position: relative;
        top: -40px;
    }
}
